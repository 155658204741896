import React, { useEffect, useState } from 'react';
import SideNavBar from '../Component/SideNavBar';
import NavBars from '../Component/NavBars';
import '../Assets/css/sideNavBar.css';

// Css file
import '../Assets/css/studentHome.css';

import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { Avatar } from 'primereact/avatar';
import { allImages } from '../Images';
import { HiLocationMarker } from 'react-icons/hi';

// For filter option
import { Dropdown } from 'primereact/dropdown';

// Swiper start
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Swiper end

// Sticybox start
import StickyBox from 'react-sticky-box';
import { studentPosts } from '../axios/student.axios';
// Sticybox end

import config from '../config/config';

const courses = [
  { name: 'Graphics Designer', code: 'Graphics Designer' },
  { name: 'UX/UI Designer', code: 'UX/UI Designer' },
  { name: '3D Animator', code: '3D Animator' },
  { name: '2D Animator', code: '2D Animator' },
  { name: 'Fullstack Developer', code: 'Fullstack Developer' },
  { name: 'VFX', code: 'VFX' },
  { name: 'Gaming Design', code: 'Gaming Design' },
  { name: 'Interior Design', code: 'Interior Design' },
  { name: 'All Courses', code: 'All Courses' },
];

const tools = [
  { name: 'Photoshop', code: 'Photoshop' },
  { name: 'Illustrator', code: 'Illustrator' },
  { name: 'Indesign', code: 'Indesign' },
  { name: 'Aftereffects', code: 'Aftereffects' },
  { name: '3D xmax', code: '3D xmax' },
  { name: 'MAYA', code: 'MAYA' },
  { name: 'Adobe XD', code: 'Adobe XD' },
  { name: 'Figma', code: 'Figma' },
];

// const NavBar = () => {
//   const popup = () => {
//     alert("Need to showing the upload works popup");
//   };
//   return (
//     <StickyBox offsetTop={0} className="top-index">
//       <Navbar expand="lg" className="bg-black">
//         <Container fluid className="mx-3">
//           <Navbar.Brand
//             href="#home"
//             className="text-light text-capitalize fs-32 fw-400"
//           >
//             Move on
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="me-auto">
//               <Nav.Link href="#home">Home</Nav.Link>
//               <Nav.Link href="#link">Link</Nav.Link>
//               <NavDropdown title="Dropdown" id="basic-nav-dropdown">
//                 <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//                 <NavDropdown.Item href="#action/3.2">
//                   Another action
//                 </NavDropdown.Item>
//                 <NavDropdown.Item href="#action/3.3">
//                   Something
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="#action/3.4">
//                   Separated link
//                 </NavDropdown.Item>
//               </NavDropdown>
//             </Nav>
//             {/* <Navbar.Collapse className="justify-content-end"> */}
//             <Navbar.Text className="justify-content-end text-light d-flex align-items-center justify-content-center gap-5 p-0 m-0">
//               {/* <img src={StudentProfileImage} alt="Student profile image" className="stu-profile-img"/> */}

//               <button
//                 className="btn text-light btn-transparent d-flex align-items-center justify-content-center gap-2 "
//                 onClick={popup}
//               >
//                 <MdOutlineFileUpload size={24} />
//                 <p className="fw-400 m-0 p-0">Upload Your Works</p>
//               </button>

//               <button
//                 className="btn text-light btn-transparent d-flex align-items-center justify-content-center gap-2 "
//                 onClick={popup}
//               >
//                 <Avatar
//                   image={allImages.studentProfileHome}
//                   shape="circle"
//                   className="p-0 m-0 stuProfile-image"
//                 />
//                 <p className="fw-400 m-0 p-0">Rajasekar</p>
//               </button>
//             </Navbar.Text>
//             {/* </Navbar.Collapse> */}
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     </StickyBox>
//   );
// };

const StudentHome = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  const [selectedTools, setSelectedTools] = useState(null);

  const newses = [
    {
      title: 'Tomorrow will be a holiday',
      time: '3hrs ago',
    },
    {
      title: 'Advance Bakrid wishes',
      time: '4hrs ago',
    },
    {
      title: 'Student must enter the class on time',
      time: '5hrs ago',
    },
    {
      title: 'During online class must student turn on the camera',
      time: '6hrs ago',
    },
    {
      title: 'NSDC update : Monthly two leaves only allowed',
      time: '7hrs ago',
    },
    {
      title:
        'Vijayragavn won the graphic designing prize at the Technic Symposium event held at NPR College.',
      time: '8hrs ago',
    },
    {
      title:
        'We had planned a project review for the recently finished UX/UI batch, slated for 25-4-24.',
      time: '9hrs ago',
    },
  ];

  const stuWorks = [
    {
      workTitle: 'Low poly 2D Design',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkOne,
    },
    {
      workTitle: 'Photoshop Design poster',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkTwo,
    },
    {
      workTitle: 'Illustrator Design poster',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkThree,
    },
    {
      workTitle: 'Photosho Creative Poster',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkFour,
    },
    {
      workTitle: 'Digital Art',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkFive,
    },
    {
      workTitle: 'digital art with multicolors',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkSix,
    },
    {
      workTitle: '7up tin photomanipulation',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkSeven,
    },
    {
      workTitle: 'photo manipulation - Plum juice',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkEight,
    },
    {
      workTitle: 'vecto fx design sample design manipulation work',
      workDesc:
        "We've trained a model called ChatGPT which interacts in a conversational way. The dialogue format.",
      workImage: allImages.stuHomeWorkNine,
    },
  ];

  const stuHomeBanners = [
    {
      stuHomeBannerImg: allImages.bakridHomeBanner,
      stuHomeBannerHeading: 'happy bakrid day guys',
      stuHomeBannerDesc: 'Tomorrow will be a holiday',
    },
    {
      stuHomeBannerImg: allImages.evDayHomeBanner,
      stuHomeBannerHeading: 'World Environment Day',
      stuHomeBannerDesc: 'Embrace peace and health with yoga every day',
    },
    {
      stuHomeBannerImg: allImages.YogaDayHomeBanner,
      stuHomeBannerHeading: 'world yoga day',
      stuHomeBannerDesc: 'Protect the planet for a sustainable future',
    },
  ];

  const [posts,setPosts]=useState([]);

  useEffect(()=>{
    getStudentPost()
  },[])


  const getStudentPost = async()=>{
    const resp = await studentPosts({action : "get"});
    console.log("aihaiwd",resp);
    setPosts(resp?.data)
  }

  console.log("posts",posts);

  return (
    <>
      <NavBars />

      <Container fluid className='d-block'>
        <Row>
          <SideNavBar />

          <Col lg={11} md={12} className='mt-lg-2 ms-lg-1 ms-md-0'>
            {/* Carousel */}
            <Row>
              <div className='main-banner m-0 p-0'>
                <Carousel data-bs-theme='light' className='banner'>
                  {stuHomeBanners.map((stuHomeBanner, index) => (
                    <Carousel.Item
                      key={index}
                      interval={1500}
                      className='w-100'
                    >
                      <div className='banner-image-container'>
                        <img
                          src={stuHomeBanner.stuHomeBannerImg}
                          alt='Student Banner'
                          className='student-banner-image w-100 d-block'
                        />
                        <div className='banner-overlay'></div>
                      </div>
                      <Carousel.Caption className=''>
                        <h5 className='text-end fs-30 fw-700 text-capitalize'>
                          {stuHomeBanner.stuHomeBannerHeading}
                        </h5>
                        <p className='text-end fs-24 fw-500 text-capitalize'>
                          {stuHomeBanner.stuHomeBannerDesc}
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </Row>

            {/* <Sticky topOffset={80}> */}
            <Row className=''>
              <Col lg={9} md={8} className='filtered-column mt-2 mb-4'>
                {/* Filter option */}
                {/* className="sticky-bar" */}
                <StickyBox
                  offsetTop={84}
                  className='row sticky-ninety-three bg-light py-2'
                >
                  <Col
                    md={12}
                    className='sticky-element filter-section d-flex align-items-center justify-content-sm-center justify-content-lg-end  gap-3 box-shadow'
                  >
                    <Dropdown
                      value={selectedCourse}
                      onChange={(e) => setSelectedCourse(e.value)}
                      options={courses}
                      optionLabel='name'
                      placeholder='Courses'
                      className='filters p-2'
                      id='filters'
                    />
                    <Dropdown
                      value={selectedTools}
                      onChange={(e) => setSelectedTools(e.value)}
                      options={tools}
                      optionLabel='name'
                      placeholder='Tools'
                      className='filters p-2'
                      id='filters'
                    />
                  </Col>
                </StickyBox>
                {/* Student works and student details */}
                {/* className="sticky-bar-one" */}
                <Row className=''>
                  {/* Student details */}
                  <Col
                    md={3}
                    className='student-details-container p-0 m-0  my-xl-4 my-2  d-lg-flex d-none flex-column gap-4'
                  >
                    {/* Student Personal information */}
                    <StickyBox
                      offsetTop={180}
                      className='m-0 p-0 row d-flex align-items-start  justify-content-center flex-column gap-3'
                    >
                      <Col
                        md={11}
                        className='sticky-element student-profile-main-container box-shadow p-2'
                      >
                        <div className='student-profile-container'>
                          <section className='student-profile-image-container'>
                            <img
                              src={allImages.studentHomeProfileCard}
                              alt='student-profile'
                              className='h-100 w-100'
                            />
                          </section>
                          <section className='student-profile-details d-flex flex-column align-items-center justify-content-center my-2 gap-2'>
                            <div className='user-details d-flex flex-column align-items-center justify-content-center'>
                              <h3 className='text-capitalize fs-20 fw-400 '>
                                Rajasekar
                              </h3>
                              <h2 className='text-capitalize fs-16 fw-500 course-name'>
                                UX UI Design
                              </h2>
                              <p className='location-details text-capitalize fs-16 fw-500 d-flex align-items-center justify-content-center mb-2 '>
                                <HiLocationMarker className='fs-24 location' />
                                <span className='location'>Madurai</span>
                                &#x2C;&nbsp;&nbsp;
                                <span className='location'>India</span>
                              </p>
                            </div>
                            <button className='fs-16 fw-400 btn btn-go-profile border border-0'>
                              Go To Profile
                            </button>
                          </section>
                        </div>
                      </Col>
                    </StickyBox>
                    {/* DESC Details */}
                    {/* <Row className="m-0 p-0  d-flex align-items-start justify-content-center flex-column gap-3">
                        <Col
                          lg={11}
                          className="student-desc-profile-container box-shadow"
                        ></Col>
                      </Row> */}
                  </Col>
                  {/* Student Work Card */}
                  <Col
                    lg={9}
                    md={12}
                    sm={12}
                    className='card-main-container my-2 p-0'
                  >
                    { posts?.length != 0 ? posts.map((items) => (
                      <div
                        className='card-container p-0 m-0 bg-light box-shadow mb-4'
                        key={items.id}
                      >
                        {/* Work Description */}
                        <div className='student-card-information d-flex alig-items-center  justify-content-center  gap-2 p-3'>
                          {/* Student card profile image */}
                          <div className='student-image d-flex align-items-center justify-content-center '>
                            <Avatar
                              image={allImages.studentProfileHome}
                              shape='circle'
                              className='p-0 m-0 stuPost-image'
                            />
                          </div>
                          {/* Student work descriptions */}
                          <div className='student-content d-flex align-items-start justify-content-center flex-column p-2'>
                            <h1 className='stu-work-heading fs-18 fw-600 text-capitalize'>
                              {items.title}
                            </h1>
                            <p className='stu-work-desc fs-16 fw-400 text-capitalize'>
                              {items.aboutProject}
                            </p>
                            <h1 className='stu-work-heading fs-18 fw-600'>
                              Tools Used : <a href='#'>{items.tools}</a> 
                              {/* &{' '}
                              <a href='#'>Maya</a> */}
                            </h1>
                          </div>
                          <div className='student-work-share d-flex align-items-center justify-content-center flex-column'>
                            <button className='bg-transparent border border-0 outline-0'>
                              <img
                                src={allImages.stuWorkShare}
                                alt='Share your work'
                                className='img-fluid'
                              />
                              <h6 className='fs-14 fw-400'>Share</h6>
                            </button>
                          </div>
                        </div>
                        {/* Work image */}
                        <div className='student-work-information p-3 pt-0'>
                          <img
                            src={`${config.image_Url}/posts/${items?.img}`}
                            className='student-work-image'
                            alt={items.title}
                          />
                        </div>
                      </div>
                    )):<></>}
                  </Col>
                </Row>
              </Col>
              {/* News and places students */}
              <Col lg={3} md={4} className=''>
                {/* Updated news */}

                <StickyBox
                  offsetTop={84}
                  className='sticky-ninety-three news-panel my-2 d-md-flex d-none flex-column gap-2 py-2 '
                >
                  <Row className='ps-2 pe-lg-2 pe-0'>
                    <Col
                      md={12}
                      className='news-section-container box-shadow  p-0 m-0'
                    >
                      <div className='news-section p-0 m-0 '>
                        <div className='d-flex align-items-center justify-content-center updated-news-heading-container'>
                          <h1 className='updated-news-heading fs-20 fw-400 p-0 m-0'>
                            News
                          </h1>
                        </div>
                        <div className='updated-news'>
                          <ul className='m-3'>
                            {newses.map((item) => (
                              <li key={item.id} className='mb-3'>
                                <h3 className='fs-20 fw-400'>{item.title}</h3>
                                <small className='fs-14 updated-news-time'>
                                  {item.time}
                                </small>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='ps-2 pe-lg-2 pe-0'>
                    <Col md={12} className='p-0 m-0'>
                      <section className='placed-students-section position-relative bg-light'>
                        <div className='p-section-sub-container  p-4  gap-2  box-shadow'>
                          {/*
                        <div className="p-section-sub-container  p-4 d-flex flex-column gap-2 bg-light box-shadow" id="pla-student-carousel"> */}
                          <div className='placed-students-heading d-flex align-items-center    justify-content-start'>
                            <h1 className='fs-20 fw-400  p-0 m-0'>
                              Places Students
                            </h1>
                          </div>

                          <SwiperComponent />
                        </div>
                        <div className='carousel-indicator-container'></div>
                      </section>
                    </Col>
                  </Row>
                </StickyBox>
              </Col>
            </Row>
            {/* </Sticky> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

// Placed students swiper carousel

const SwiperComponent = () => {
  const placedStudents = [
    {
      placedStuImage: allImages.plaStuHomeOne,
      placedStuName: 'Arjun',
      placedStuCompanyName: 'TCS',
    },
    {
      placedStuImage: allImages.plaStuHomeTwo,
      placedStuName: 'Anand',
      placedStuCompanyName: 'Infosys',
    },
    {
      placedStuImage: allImages.studentHomeProfileCard,
      placedStuName: 'Balaji',
      placedStuCompanyName: 'Wipro',
    },
    {
      placedStuImage: allImages.plaStuHomeTwo,
      placedStuName: 'Bharath',
      placedStuCompanyName: 'HCL Technologies',
    },
    {
      placedStuImage: allImages.plaStuHomeOne,
      placedStuName: 'Chandrasekar',
      placedStuCompanyName: 'Tech Mahindra',
    },
    {
      placedStuImage: allImages.plaStuHomeTwo,
      placedStuName: 'Chidambaram',
      placedStuCompanyName: 'IBM India',
    },
    {
      placedStuImage: allImages.studentHomeProfileCard,
      placedStuName: 'Dinesh',
      placedStuCompanyName: 'Accenture India',
    },
    {
      placedStuImage: allImages.plaStuHomeTwo,
      placedStuName: 'Durai',
      placedStuCompanyName: 'Cognizant',
    },
    {
      placedStuImage: allImages.plaStuHomeOne,
      placedStuName: 'Elango',
      placedStuCompanyName: 'Capgemini',
    },
    {
      placedStuImage: allImages.plaStuHomeTwo,
      placedStuName: 'Ezhilan',
      placedStuCompanyName: 'Oracle India',
    },
    {
      placedStuImage: allImages.studentHomeProfileCard,
      placedStuName: 'Franklin',
      placedStuCompanyName: 'Mindtree',
    },
    {
      placedStuImage: allImages.plaStuHomeTwo,
      placedStuName: 'Fazil',
      placedStuCompanyName: 'Mphasis',
    },
    {
      placedStuImage: allImages.plaStuHomeOne,
      placedStuName: 'Gopal',
      placedStuCompanyName: 'Birlasoft',
    },
    {
      placedStuImage: allImages.plaStuHomeTwo,
      placedStuName: 'Ganesh',
      placedStuCompanyName: 'Hexaware Technologies',
    },
    {
      placedStuImage: allImages.studentHomeProfileCard,
      placedStuName: 'Hari',
      placedStuCompanyName: 'Zensar Technologies',
    },
    {
      placedStuImage: allImages.plaStuHomeOne,
      placedStuName: 'Iyyappan',
      placedStuCompanyName: 'Sonata Software',
    },
  ];

  return (
    <Swiper
      spaceBetween={10}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        dynamicBullets: true,
      }}
      navigation={true}
      modules={[Pagination, Autoplay, Navigation]}
      className='mySwiper position-relative d-flex align-items-center justify-content-center flex-column gap-5'
      style={{ height: '100%' }}
    >
      {placedStudents.map((details, index) => (
        <SwiperSlide key={index} interval='1000' className='slider'>
          <div className='swiper-image-container'>
            <img
              src={details.placedStuImage}
              alt={details.placedStuImage}
              className='placed-student-image h-100 w-100 d-block'
            />
          </div>

          <div className='swiper-caption d-flex align-items-center justify-content-center flex-column my-lg-0 my-md-2'>
            <h5 className='fs-20 fw-600 text-dark'>{details.placedStuName}</h5>
            <h3 className='fs-15 fw-500 text-dark text-uppercase text-center'>
              {details.placedStuCompanyName}
            </h3>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default StudentHome;
