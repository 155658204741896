import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { allImages } from "../Images";
import OtpInput from "react-otp-input";
import Countdown, { zeroPad } from "react-countdown";
import { useNavigate, useLocation } from "react-router";
import { toast } from "react-toastify";
import { resendOTP } from "../axios/student.axios";
import { decryptData, obfuscateEmail } from "../config/common";

const OTPVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [otp, setOtp] = useState("");
  const [verOtp, setVerOtp] = useState(decryptData(state?.OTP || 0));
  const [time, setTime] = useState(Date.now() + 30000);
  const [dummy, setDummy] = useState(false);

  const renderer = ({ hours, minutes, seconds }) => (
    <span className="otpVerify__countdown">
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );

  const verifyOtp = async () => {
    if (otp === "") return toast.error("Enter OTP!!!");
    if (parseInt(otp) !== parseInt(verOtp))
      return toast.error("Please Enter valid OTP");
    localStorage.setItem("token", state?.token);
    toast.success("Student verified successfully");
    setTimeout(() => {
      // onClick={() => navigate("/resetPassword")}
      navigate("/resetPassword", { state: state });
    }, 2000);
  };

  const onResendOtp = async () => {
    const resp = await resendOTP({ email: state?.email });
    if (resp?.status) {
      setVerOtp(decryptData(resp?.OTP));
      setTime(Date.now() + 30000);
      toast[resp?.success ?? "error"](resp?.msg ?? "Error");
    } else {
      toast.error("Error on resend");
    }
  };

  return (
    <>
      <Container fluid className="p-0 signIn__commonBg cmn__bgAligner">
        <Row className="h-100 align-items-center">
          <Col
            xl={6}
            className=" d-flex justify-content-center align-items-center"
          >
            <img
              src={allImages.logo}
              className="img-fluid signIn__logo"
              alt=""
            />
          </Col>
          <Col xl={6} className="d-flex justify-content-center">
            <div className="custom-pad  text-center forget__formHolder">
              <img src={allImages.emailChecker} className="img-flui" alt="" />

              <h5 className="text-center text-dark singIn__formTitle">
                Enter Your OTP !
              </h5>
              <p className="m-0  fs-24 otpVerify__hint text-center">
                Enter The 4-Digit Code Sent to <br />
                <span>{obfuscateEmail(state?.email)}</span>
              </p>
              <div className="cmn__otpInpHolder d-flex justify-content-center mt-4">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  className="gap-5"
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <div class="my-3 d-flex justify-content-between flex-column align-items-center">
               {time ? (
                  <Countdown
                    onComplete={() => {
                      setTime(null);
                      setDummy(!dummy);
                    }}
                    date={time}
                    renderer={renderer}
                  />
                ) : (
                  <p className="m-0 otpVerify__hint text-center">0:00</p>
                )}
                <button
                  className="otpVerify__plainbutton fs-6"
                  disabled={time > Date.now()}
                  onClick={() => onResendOtp()}
                >
                  Resend OTP
                </button>
              </div>
              <button
                className="primary__btn rounded-1 fs-6"
                onClick={() => verifyOtp()}
              >
                Verify OTP
              </button>
              <div class="my-3">
                <button className="otpVerify__plainbutton fs-6" onClick={()=>navigate(-1)} >Cancel</button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OTPVerification;
