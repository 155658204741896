import React from 'react';
import '../Assets/css/studentHome.css';
import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Avatar } from 'primereact/avatar';
import { allImages } from '../Images';
import { MdOutlineFileUpload } from 'react-icons/md';
import StickyBox from 'react-sticky-box';

const NavBars = () => {
  const popup = () => {
    alert('Need to showing the upload works popup');
  };
  return (
    <>
      <StickyBox offsetTop={0} className='top-index'>
        <Navbar expand='lg' className='bg-black'>
          <Container fluid className='mx-3'>
            <Navbar.Brand
              href='#home'
              className='text-light text-capitalize fs-32 fw-400'
            >
              Move on
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='me-auto'>
                <Nav.Link href='#home'>Home</Nav.Link>
                <Nav.Link href='#link'>Link</Nav.Link>
                <NavDropdown title='Dropdown' id='basic-nav-dropdown'>
                  <NavDropdown.Item href='#action/3.1'>Action</NavDropdown.Item>
                  <NavDropdown.Item href='#action/3.2'>
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href='#action/3.3'>
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href='#action/3.4'>
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              {/* <Navbar.Collapse className="justify-content-end"> */}
              <Navbar.Text className='justify-content-end text-light d-flex align-items-center justify-content-center gap-5 p-0 m-0'>
                {/* <img src={StudentProfileImage} alt="Student profile image" className="stu-profile-img"/> */}

                <button
                  className='btn text-light btn-transparent d-flex align-items-center justify-content-center gap-2 '
                  onClick={popup}
                >
                  <MdOutlineFileUpload size={24} />
                  <p className='fw-400 m-0 p-0'>Upload Your Works</p>
                </button>

                <button
                  className='btn text-light btn-transparent d-flex align-items-center justify-content-center gap-2 '
                  onClick={popup}
                >
                  <Avatar
                    image={allImages.studentProfileHome}
                    shape='circle'
                    className='p-0 m-0 stuProfile-image'
                  />
                  <p className='fw-400 m-0 p-0'>Rajasekar</p>
                </button>
              </Navbar.Text>
              {/* </Navbar.Collapse> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </StickyBox>
    </>
  );
};

export default NavBars;
