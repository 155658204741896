import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { allImages } from '../Images';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

export const SuggestFriends = () => {
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const location = useLocation()
console.log("phone1phone1",phone1);
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    friendOneName: Yup.string()
      .required('Name 1 is required')
      .min(2, 'Name 1 must be at least 2 characters long'),
    friendOnePhone: Yup.string()
      .required("Contact Number 1 is required")
      .matches(/^\+\d{1,3}\d{10}$/, "Contact Number 1 is not valid")
      .length(13,"Please enter vaild number"),
    // friendTwoName: Yup.string()
    //   .required('Name 2 is required')
    //   .min(2, 'Name 2 must be at least 2 characters long'),
    // friendTwoPhone: Yup.string()
    //   .required("Contact Number 2 is required")
    //   .matches(/^\+\d{1,3}\d{10}$/, "Contact Number 2 is not valid")
    //   .length(13,"Please enter vaild number"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    console.log('onggggg', values);
    setSubmitting(false);
    const suggestFriend = [{friendOneName:values.friendOneName,friendOnePhone:values.friendOnePhone},{friendTwoName:values.friendTwoName,friendTwoPhone:values.friendTwoPhone}]
    navigate("/studentDetails",{state:{...location.state,suggestFriend}}); 
  }

  const onSkip = ()=>{
    navigate("/studentDetails",{state:{...location.state,suggestFriend:[]}}); 
  }

  return (
    <>
      <Helmet>
        <title>Suggest Your Friends</title>
      </Helmet>

      <Container fluid className='p-0 signIn__commonBg cmn__bgAligner'>
        <Row className='h-100 align-items-center'>
          <Col
            md={6}
            className='d-flex justify-content-center align-items-center'
          >
            <img
              src={allImages.logo}
              className='img-fluid signIn__logo'
              alt='Logo'
            />
          </Col>
          <Col md={6} className='d-flex justify-content-center'>
            <div className='custom-pad text-center preference__formHolder'>
              <h3 className='fw-semibold mb-5 pb-5'>Suggest Your Friends</h3>
              <Formik
                initialValues={{
                  friendOneName: '',
                  friendOnePhone: phone1,
                  friendTwoName: '',
                  friendTwoPhone: phone2,
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div className='form-floating mb-4'>
                      <Field
                        type='text'
                        className='form-control rounded rounded-0 border border-dark'
                        id='friendOneName'
                        name='friendOneName'
                        placeholder='Name 1'
                      />
                      <label htmlFor='friendOneName'>Name 1</label>
                      <ErrorMessage
                        name='friendOneName'
                        component='div'
                        className='error-fields'
                      />
                    </div>
                    <div className='form-floating mb-4'>
                      <PhoneInput
                        defaultCountry='IN'
                        displayInitialValueAsLocalNumber
                        placeholder='Enter phone number'
                        className='form-control rounded rounded-0 border border-dark'
                        value={phone1}
                        onChange={(value) => {
                          console.log("valuevaluevalue",value?.toString().length);
                          // if(value?.toString().length <= 13){
                            setPhone1(value);
                            setFieldValue("friendOnePhone", value);
                          // }
                        }}
                      />
                      <label htmlFor='friendOnePhone'>Contact Number 1</label>
                      <ErrorMessage
                        name='friendOnePhone'
                        component='div'
                        className='error-fields'
                      />
                    </div>
                    <div className='form-floating mb-4'>
                      <Field
                        type='text'
                        className='form-control rounded rounded-0 border border-dark'
                        id='friendTwoName'
                        name='friendTwoName'
                        placeholder='Name 2'
                      />
                      <label htmlFor='friendTwoName'>Name 2</label>
                      <ErrorMessage
                        name='friendTwoName'
                        component='div'
                        className='error-fields'
                      />
                    </div>
                    <div className='form-floating mb-4'>
                      <PhoneInput
                        defaultCountry='IN'
                        displayInitialValueAsLocalNumber
                        placeholder='Enter phone number'
                        className='form-control rounded rounded-0 border border-dark'
                        value={phone2}
                        onChange={(value) => {
                            setPhone2(value);
                            setFieldValue("friendTwoPhone", value);
                        }}
                      />
                      <label htmlFor='friendTwoPhone'>Contact Number 2</label>
                      <ErrorMessage
                        name='friendTwoPhone'
                        component='div'
                        className='error-fields'
                      />
                    </div>
                    <div className='text-end'>
                      <button
                        type='button'
                        className='otpVerify__plainbutton mt-3 fs-6 fs-med pe-3'
                        onClick={() => onSkip()}
                        // onClick={() => navigate('/studentDetails')}
                      >
                        Skip
                      </button>
                      <button
                        type='submit'
                        className='primary__btn rounded-1 mt-5 fs-6 fs-med'
                      >
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
