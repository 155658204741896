import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import Select from 'react-select';
import { RiImageEditFill } from 'react-icons/ri';
const EditProfileModal = ({ showModal, handleClose, allImages }) => {
  const locationOptions = [
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Madurai', label: 'Madurai' },
    { value: 'Bangalore', label: 'Bangalore' },
    // add more options here
  ];
  const interestOptions = [
    { value: 'Full Stack Web Developer', label: 'Full Stack Web Developer' },
    { value: 'UI/UX Designer', label: 'UI/UX Designer' },
    { value: 'Visual Designer', label: 'Visual Designer' },
    // add more options here
  ];
  const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
  const [selectedInterestOptions, setSelectedInterestOptions] = useState([]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'black' : 'dark',
      padding: '10px',
      borderRadius: '0px',
      '&:hover': {
        borderColor: state.isFocused ? 'black' : 'dark',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '0px',
    }),
  };
  const handleLocationChange = (selected) => {
    setSelectedLocationOptions(selected);
  };
  const handleInterestChange = (selected) => {
    setSelectedInterestOptions(selected);
  };

  return (
    <Modal
      size='lg'
      centered
      show={showModal}
      onHide={handleClose}
      // dialogClassName="modal-50w"
    >
      <Modal.Body className='position-relative'>
        <div
          onClick={handleClose}
          className='close-button cursor-pointer position-absolute top-0 end-0 m-3'
        >
          <IoMdClose size={22} />
        </div>
        <Row className='px-4 pt-4'>
          <Col lg={6} className='mb-3'>
            <div class='edit-pic position-relative'>
              <picture className='position-relative d-block'>
                <img
                  src={allImages.studentHomeProfileCard}
                  className='w-100 img-fluid'
                  alt={allImages.studentHomeProfileCard}
                />
              </picture>
              <button className='btn-primary bottom-0 right-0 position-absolute m-2 p-1 btn'>
                <RiImageEditFill size={28} />
              </button>
            </div>
          </Col>
          <Col lg={6}>
            <h2 className='fw-bold text-prime'>Edit Profile</h2>
            <div className='edit-modal'>
              <div className='mb-4 m-0 w-100 form-floating'>
                <input
                  type='text'
                  className='form-control  rounded-0 border-dark'
                  id='qualificationStd'
                  placeholder='BE Mechanical'
                />
                <label
                  htmlFor='qualificationStd'
                  className='form-label text-secondary'
                >
                  Qualification
                </label>
              </div>
              <div className='mb-4'>
                <Select
                  isMulti
                  value={selectedInterestOptions}
                  onChange={handleInterestChange}
                  options={interestOptions}
                  placeholder='Area of Interest'
                  styles={customStyles}
                  className='rounded-0 border-dark '
                  id='areaIntrestStd'
                />
              </div>
              <div className='mb-4 '>
                <Select
                  isMulti
                  value={selectedLocationOptions}
                  onChange={handleLocationChange}
                  options={locationOptions}
                  placeholder='Preferred Location'
                  styles={customStyles}
                  className='rounded-0 border-dark '
                  id='preferredLocation'
                />
                {/* <label htmlFor="preferredLocation" className="form-label">
      Preferred Location
    </label> */}
              </div>
              <div className='mb-3 text-end'>
                <button className='btn btn-transparent px-5'>Cancel</button>
                <button className='btn btn-primary px-5 rounded rounded-1'>
                  Save
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EditProfileModal;
