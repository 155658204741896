let key = {};
let Back_Url = '';
let image_Url = '';
let AdminApi = '',
  UserAPI = '';
var EnvName = 'local';
key.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

if (EnvName === 'local') {
  Back_Url = 'http://localhost:5454/admin';
  image_Url = 'http://localhost:5454';
  UserAPI = 'http://localhost:5454/student';
}

if (EnvName === 'production') {
  Back_Url = 'https://api.makemoveon.com/admin';
  image_Url = 'https://api.makemoveon.com';
  UserAPI = 'https://api.makemoveon.com/student';
}

key = {
  AdminAPI: `${Back_Url}`,
  image_Url: image_Url,
  UserAPI,
  emailRegex:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/,
  Secret: 'M0ve0n#456',
};

export default key;
