import './App.css';
import './Assets/css/Style.css';
import './Assets/css/Merging.css';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import SignIn from './Screens/SignIn';
import OTPVerification from './Screens/OTPVerification';
import ResetPassword from './Screens/ResetPassword';
import { Preference } from './Screens/Preference';
import { ForgetPassword } from './Screens/ForgetPassword';
import { SuggestFriends } from './Screens/SuggestFriends';
import { StudentDetails } from './Screens/StudentDetails';
import { StudentProfile } from './Screens/StudentProfile';
// Testing
import { Sample2 } from './Screens/Testing/Sample2';
import StudentHome from './Screens/StudentHome';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// SuggestFriends
function App() {
  const userdata = useSelector((state) => state?.redux?.userdata);
  const [token, setToken] = useState(true);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    setToken(getToken ? true : false);
  }, [userdata]);
  console.log('token', token);
  const authenticate = (comp) => {
    return token ? comp : <Navigate to={'/'} replace />;
  };

  return (
    <>
      <Routes>
        <Route path='/' element={<SignIn />} />
        <Route path='/OtpVerify' element={<OTPVerification />} />
        <Route path='/resetPassword' element={<ResetPassword />} />
        <Route path='/studentPreference' element={<Preference />} />
        <Route path='/ForgetPassword' element={<ForgetPassword />} />
        <Route path='/suggestFriends' element={<SuggestFriends />} />
        <Route path='/studentDetails' element={<StudentDetails />} />
        <Route path='/studentProfile' element={<StudentProfile />} />
        <Route path='/studentHome' element={<StudentHome />} />
        <Route path='/test1' element={<Sample2 />} />
      </Routes>
    </>
  );
}

export default App;
