import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { allImages } from '../Images';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { studentLogin } from '../axios/student.axios';
import {toast} from 'react-toastify';

const SignIn = () => {
    const navigate = useNavigate();
    const [passwordCharger,setPasswordCharger]=useState(false);
    const [err,setErr]=useState({})

    const onSubmit = async(values, { setSubmitting })=>{
        console.log("ajkjakjkjk",values);
        setSubmitting(false);

        const resp = await studentLogin(values);
        toast[resp?.success ?? "error"](resp?.msg ?? "Error");
        console.log("jajajjjaja",resp);
        if(resp?.status){
            setTimeout(()=>{
                if(!resp?.data?.passwordChanged) navigate("/resetPassword",{state:resp?.data});
                else{
                    if(resp?.data?.initalSetup){
                        localStorage.setItem("token", resp?.data?.token ?? "authToken");
                        navigate("/studentHome",{state:resp?.data});
                    }
                    else navigate("/studentPreference",{state:resp?.data})
                }
            },1500)
        }else{
            setErr({password:resp?.msg})
        }
        setSubmitting(true);

        // navigate('/OtpVerify');
    }
    
    return (
        <>
        <Helmet>
            <title>Sign In</title>
        </Helmet>
        <Container fluid className='p-0 signIn__commonBg cmn__bgAligner'>
            <Row className='h-100 align-items-center'>
                <Col xl={6} className=' d-flex justify-content-center align-items-center'>
                    <img src={allImages.logo} className='img-fluid signIn__logo' alt='' />
                </Col>
                <Col xl={6} className='d-flex justify-content-center'>
                    <div className=" custom-pad text-center preference__formHolder">
                        <h3 className="fw-semibold mb-5 pb-5">Sign-In</h3>
                        <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string().email('Invalid email address').required('Enter the field'),
                                password: Yup.string().required('password must')
                            })}
                            onSubmit={onSubmit}
                        >
                            <Form>
                                <div class="signin-email mb-4">
                                    <div className="form-floating">
                                        <Field
                                            type="email"
                                            className="form-control rounded rounded-0 border border-dark"
                                            id="email"
                                            name="email"
                                            placeholder="name@example.com"
                                        />
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <ErrorMessage name="email" component="div" className="error-fields" />
                                </div>
                                <div class="sign-inpass mb-4">
                                    <div className="form-floating ">
                                        <Field
                                            type={passwordCharger ? "text" : "password"}
                                            className="form-control rounded rounded-0 border border-dark"
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                        />
                                        <FaEye className='signIn__eye pointer' onClick={()=>setPasswordCharger(!passwordCharger)} size={23} />
                                        <label htmlFor="password">Password</label>
                                    </div>
                                    <ErrorMessage name="password" component="div" className="error-fields" />
                                </div>
                                <div className='sign__formInps d-flex flex-column justify-content-center align-items-center gap-4'>
                                    <NavLink to='/ForgetPassword'><p className='m-0 fs-16'>Forgot Password</p></NavLink>
                                    <button type="submit" className='primary__btn rounded-1 mt-5'>Submit</button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default SignIn;
