export const allImages = {
  logo: require('./Assets/Images/logo.svg').default,
  emailChecker: require('./Assets/Images/mailchecker.svg').default,
  email: require('./Assets/Images/email.svg').default,
  passwordSuccesss: require('./Assets/Images/passwordSuccess.svg').default,
  // exampleProfile:require('./Assets/Images/profileImage.jpg'),
  studentProfileHome: require('./Assets/Images/studentProfile.svg').default,
  stuWorkShare: require('./Assets/Images/stuWorkShare.svg').default,
  bakridHomeBanner: require('./Assets/Images/banner/bakrid.png'),
  evDayHomeBanner: require('./Assets/Images/banner/environment-day.jpg'),
  YogaDayHomeBanner: require('./Assets/Images/banner/yoga-day.jpg'),
  studentHomeProfileCard: require('./Assets/Images/profile/userProfileCard.png'),
  stuHomeWorkOne: require('./Assets/Images/studentWork/studentWorkNew.png'),
  stuHomeWorkTwo: require('./Assets/Images/studentWork/studentWorkOne.jpeg'),
  stuHomeWorkThree: require('./Assets/Images/studentWork/studentWorkTwo.jpeg'),
  stuHomeWorkFour: require('./Assets/Images/studentWork/studentWorkThree.jpeg'),
  stuHomeWorkFive: require('./Assets/Images/studentWork/painting.jpeg'),
  stuHomeWorkSix: require('./Assets/Images/studentWork/painting-two.jpeg'),
  stuHomeWorkSeven: require('./Assets/Images/studentWork/photo-manipulation.jpeg'),
  stuHomeWorkEight: require('./Assets/Images/studentWork/photo-manipulation-one.jpeg'),
  stuHomeWorkNine: require('./Assets/Images/studentWork/photo-manipulation-two.jpeg'),
  plaStuHomeOne: require('./Assets/Images/placedStudents/placedStudent.png'),
  plaStuHomeTwo: require('./Assets/Images/placedStudents/placedStudent-two.png'),
};
