import React, { useState } from "react";
import { allImages } from "../Images.js";
import { Col, Row } from "react-bootstrap";
import StudentProfileCard from "../Component/StudentProfileCard.js"
import EditProfileModal from '../Modals/EditProfileModal.js';
import UploadTab from "../Component/UploadTab.js";
import { Helmet } from 'react-helmet';
import SideNavBar from "../Component/SideNavBar.js";
import NavBars from "../Component/NavBars";


export const StudentProfile = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditModalClose = () => setShowEditModal(false);
  const handleEditModalShow = () => setShowEditModal(true);
  return (
    <>
      <Helmet>
        <title>Student Profile</title>
      </Helmet>
      <NavBars/>
        <div className="home__page-bg overflow-x-hidden">
          <Row className=" p-3 g-4 student-profile-board">
            <Col xl={1} lg={1} className="sideNavBarProfile">
              <SideNavBar />
            </Col>
            <Col xl={7} lg={6} className="bg-white profile__tab mx-auto p-4">
              <UploadTab />
            </Col>
            <Col xl={3} lg={4} className="px-4 profile-page d-lg-block d-none">
              <StudentProfileCard setEditModal={handleEditModalShow} />
              {/* <Button   variant="primary" className="rounded rounded-1 m-3 mx-auto d-block">
                Upload Works
              </Button> */}
              <EditProfileModal showModal={showEditModal} handleClose={handleEditModalClose} allImages={allImages} />
            </Col>
          </Row>
        </div>
     
    </>
  );
};


