import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FiPlus } from 'react-icons/fi';
import { LuTrash } from 'react-icons/lu';
import {MultiSelect} from 'primereact/multiselect';
import {Chips} from 'primereact/chips';

const Tools = [
  { name: "Photoshop", code: "NY" },
  { name: "Illustrator", code: "RM" },
  { name: "Indesign", code: "LDN" },
  { name: "Gimp", code: "IST" },
  { name: "WPS", code: "PRS" }
];

const UploadModal = ({ showUploadModal, setShowUploadModal }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selTools, setSelTools] = useState([]);
  const [value, setValue] = useState([]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      show={showUploadModal}
      onHide={() => setShowUploadModal(false)}
      dialogClassName="modal-70w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Body>
        <Modal.Header className="border border-0 m-0 p-0" closeButton />
        <Row>
          <Col lg={6} className="Modal-uploaded">
            {!uploadedImage && (
              <aside className="Modal-uploaded-Works-upload mx-auto">
                <div className="upload-border">
                  <input
                    type="file"
                    accept=".jpg, .png, .svg"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                    id="upload-button"
                  />
                  <label
                    htmlFor="upload-button"
                    className="upload-button btn pt-4 btn-dark rounded rounded-0 text-white"
                    type="button"
                  >
                    <FiPlus size={"32px"} />
                  </label>
                </div>
              </aside>
            )}
            {uploadedImage && (
              <div className="position-relative">
                <img
                  src={uploadedImage}
                  className="img-fluid w-100"
                  alt="Uploaded"
                />
                <div
                  onClick={() => setUploadedImage(null)}
                  className="badge d-flex justify-content-center gap-1 p-2 position-absolute top-0 left-0 m-2 bg-dark bg-opacity-75 cursor-pointer remove__image"
                >
                  <LuTrash /> Remove
                </div>
              </div>
            )}
          </Col>
          <Col lg={6} className="upload-form">
            <div className="upload-forms">
              <h3 className="my-3">Upload Your Works</h3>
              <div className="mx-0 form-floating my-3 w-100">
                <input
                  type="text"
                  className="form-control rounded rounded-0 border border-secondary"
                  id="project-title"
                  placeholder="Project Title"
                />
                <label htmlFor="project-title">
                  Enter Your Project Title
                </label>
              </div>
              <div className="mx-0 form-floating my-3 w-100">
                <textarea
                  className="form-control rounded rounded-0 border border-secondary"
                  placeholder="Project Details"
                  id="projectDetails"
                  style={{ height: "90px" }}
                ></textarea>
                <label htmlFor="projectDetails">Project Details</label>
              </div>
              <div className="mx-0 my-3">
                <MultiSelect
                  value={selTools}
                  onChange={(e) => setSelTools(e.value)}
                  options={Tools}
                  optionLabel="name"
                  display="chip"
                  placeholder="Add Your Tools Used"
                  maxSelectedLabels={5}
                  className="w-full multisel border border-dark rounded rounded-0 profile-tabs-mul"
                />
              </div>
              <div className="mx-0 my-3">
                <div className="student__profile_chips p-fluid">
                  <Chips
                    value={value}
                    onChange={(e) => setValue(e.value)}
                    separator=","
                    placeholder="Add Some Tags for People Find"
                  />
                </div>
              </div>
              <div className="mx-0 my-3">
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-transparent mt-3 fs-6 fs-med pe-3"
                    onClick={() => setShowUploadModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="primary__btn rounded-1 mt-5 fs-6 fs-med">
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
