import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { allImages } from "../Images";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { forgetPassword } from "../axios/student.axios";
export const ForgetPassword = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });

  const onSumbitFun = async (values, { setSubmitting }) => {
    // Handle form submission here, e.g., call an API
    console.log(values);
    // setSubmitting(false);

    const resp = await forgetPassword({ email: values?.email });
    toast[resp?.success ?? "error"](resp?.msg ?? "Error");
    if (resp?.status)
      navigate("/OtpVerify", {
        state: { email: values?.email, OTP: resp?.OTP },
      });
  };
  return (
    <>
      <Helmet>
        <title>Forget Password</title>
      </Helmet>
      <Container fluid className="p-0 signIn__commonBg cmn__bgAligner">
        <Row className="h-100 align-items-center">
          <Col
            xl={6}
            className=" d-flex justify-content-center align-items-center"
          >
            <img
              src={allImages.logo}
              className="img-fluid signIn__logo"
              alt=""
            />
          </Col>
          <Col xl={6} className="d-flex justify-content-center">
            <div className="custom-pad text-center forget__formHolder">
              <img src={allImages.email} alt="" className="mb-4" />
              <h5 className="text-center fw-semibold mb-4">Enter Your Email</h5>

              <Formik
                initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={onSumbitFun}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="reset-email mb-3">
                      <div className="form-floating ">
                        <Field
                          type="email"
                          className="form-control border-dark rounded rounded-0 border border-1"
                          id="floatingInput"
                          name="email"
                          placeholder="name@example.com"
                        />
                        <label htmlFor="floatingInput">Email address</label>
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-fields"
                      />
                    </div>

                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <button
                        type="submit"
                        className="primary__btn rounded-1 mt-5 fs-20 fw-medium"
                        disabled={isSubmitting}
                      >
                        Reset Password
                      </button>
                      <button
                        type="button"
                        className="btn rounded-1 mt-3"
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
