import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { allImages } from "../Images";
import PhoneInput from "react-phone-number-input";
import ScrollSpy from "react-ui-scrollspy";
import "react-phone-number-input/style.css";
import { useLocation, useNavigate } from "react-router";
import { updateStudentDetail } from "../axios/student.axios";
import { toast } from "react-toastify";

export const StudentDetails = () => {
  const [phone, setPhone] = useState("+91");
  const [popupField, setPopupField] = useState("");
  const [formData, setFormData] = useState({});
  const [checked, setChecked] = useState(false);
  const [activeSection, setActiveSection] = useState("first");
  const [changeForm,setChangeForm]=useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  console.log("statestate",state,changeForm);
  useEffect(() => {
    if (state) setFormData(state);
  }, [state]);

  const submitData = async () => {
    const resp = await updateStudentDetail({...state,changeForm});
    toast[resp?.success ?? "error"](resp?.msg ?? "Error");
    setTimeout(()=>{
      if (resp?.status) navigate("/studentHome");
    },1000)
  };

  const togglePopup = (field) => {
    setPopupField(popupField === field ? "" : field);
  };

  const onChangeFunc = async(e)=>{
    const {value,id}=e.target
    setChangeForm({...changeForm,[id]:value})
  }

  const renderPopup = (field) => {
    if (popupField === field) {
      return (
        <div className="position-absolute custom-top text-end p-6 left-0 shadow bg-white" style={{ zIndex: 100000 }}>
          <div className="form-floating mb-5">
            <input
              type={field === "DOB" ? "date" : "text"}
              className="form-control cursor-pointer rounded rounded-0 border border-dark"
              id={field}
              placeholder={field}
              autoFocus
              disabled={field === "email"}
              onBlur={()=>setPopupField("")}
              value={changeForm[field]}
              onChange={(e)=>onChangeFunc(e)}
            />
            <label htmlFor={`${field}-input`}>Correct Data</label>
          </div>
          <button className="btn-primary btn text-end px-3" onClick={()=>setPopupField("")} >Submit</button>
        </div>
      );
    }
  };

  const renderInput = (type, field, placeholder, value) => (
    <div className="form-floating mb-5 position-relative">
      <input
        type={type}
        readOnly
        className="form-control cursor-pointer rounded rounded-0 border border-dark"
        id={field}
        placeholder={placeholder}
        value={value}
        onClick={() => togglePopup(field)}
      />
      <label htmlFor={field}>{placeholder}</label>
      {renderPopup(field)}
    </div>
  );

  const handleSectionChange = (newSection) => {
    setActiveSection(newSection);
  };

  return (
    <Container fluid className="p-0 signIn__commonBg cmn__bgAligner">
      <Row className="h-100 align-items-center">
        <Col xl={6} className="d-flex justify-content-center align-items-center">
          <img src={allImages.logo} className="img-fluid signIn__logo" alt="" />
        </Col>
        <Col xl={6} className="d-flex justify-content-center">
          <div className="text-center details__formHolder">
            <div className="student--Details__note mt-4 mx-3">
              <p className="fs-15 p-2 rounded rounded-pill bg-yellow text-black">
                <span className="fw-bold">Note: </span>If you want to alter the information given here, click the text area and make the necessary changes.
              </p>
            </div>
            <Row className="custom-pad">
              <Col lg={1}>
                <div id="list-example" className="d-flex steps-check flex-column justify-content-center align-items-center h-100 position-fixed top-0 custom-left">
                  {["first", "second", "third", "fourth"].map((section, index) => (
                    <React.Fragment key={section}>
                      <span
                        className={`btn-custom text-decoration-none fw-semibold d-flex justify-content-center align-items-center ${
                          activeSection === section ? "active-scroll-spy" : ""
                        }`}
                        data-to-scrollspy-id={section}
                      >
                        {index + 1}
                      </span>
                      {index < 3 && <div className="line-custom"></div>}
                    </React.Fragment>
                  ))}
                </div>
              </Col>
              <Col lg={11} md={10}>
                <ScrollSpy onUpdateCallback={handleSectionChange} scrollThrottle={100}>
                  {/* Student Details */}
                  <div className="student-details scrollspy-section" data-scrollspy-id="first" id="first">
                    <Row>
                      <h4 className="fs-24 fw-semibold mb-5">Student Details</h4>
                      <Col lg={6} md={12} sm={12}>
                        {renderInput("text", "name", "Name", formData?.name)}
                        {renderInput("email", "email", "Mail id", formData?.email)}
                        {renderInput("date", "DOB", "D.O.B", formData?.DOB)}
                        <div className="form-floating mb-5">
                          <PhoneInput
                            readOnly
                            defaultCountry="IN"
                            displayInitialValueAsLocalNumber={true}
                            underlineColorAndroid="transparent"
                            placeholder="Enter phone number"
                            className="form-control cursor-pointer rounded rounded-0 border border-dark"
                            value={formData?.mobileNumber}
                            onChange={setPhone}
                            onClick={() => togglePopup("mobileNumber")}
                          />
                          <label htmlFor="floatingInput">Contact Number</label>
                          {renderPopup("mobileNumber")}
                        </div>
                        {renderInput("text", "bloodGroup", "Blood Group", formData?.bloodGroup)}
                      </Col>
                      <Col lg={6} md={12}>
                        {renderInput("text", "IDno", "ID No", formData?.IDno)}
                        {renderInput("text", "nationality", "Nationality", formData?.nationality)}
                        {renderInput("text", "maritalStatus", "Martial Status", formData?.maritalStatus)}
                        {renderInput("text", "gender", "Gender", formData?.gender)}
                        {renderInput("text", "aadharNum", "Aadhar Number", formData?.aadharNum)}
                      </Col>
                    </Row>
                  </div>

                  {/* Parent Details */}
                  <div id="second" className="parent-details scrollspy-section" data-scrollspy-id="second">
                    <Row>
                      <h4 className="fs-24 fw-semibold mb-5">Parent Details</h4>
                      <Col lg={6} md={12}>
                        {renderInput("text", "fatherName", "Father's Name", formData?.fatherName)}
                        <div className="form-floating mb-5">
                          <PhoneInput
                            readOnly
                            defaultCountry="IN"
                            displayInitialValueAsLocalNumber={true}
                            underlineColorAndroid="transparent"
                            placeholder="Enter phone number"
                            className="form-control cursor-pointer rounded rounded-0 border border-dark"
                            value={formData?.fatherNumber}
                            onChange={setPhone}
                            onClick={() => togglePopup("fatherNumber")}
                          />
                          <label htmlFor="floatingInput">Father's Contact Number</label>
                          {renderPopup("fatherNumber")}
                        </div>
                        {renderInput("text", "guardianName", "Guardian's Name", formData?.guardianName)}
                        <div className="form-floating mb-5">
                          <PhoneInput
                            readOnly
                            defaultCountry="IN"
                            displayInitialValueAsLocalNumber={true}
                            underlineColorAndroid="transparent"
                            placeholder="Enter phone number"
                            className="form-control cursor-pointer rounded rounded-0 border border-dark"
                            value={formData?.guardianNumber}
                            onChange={setPhone}
                            onClick={() => togglePopup("guardianNumber")}
                          />
                          <label htmlFor="floatingInput">Gurdian's Contact Number</label>
                          {renderPopup("guardianNumber")}
                        </div>
                      </Col>
                      <Col lg={6} md={12}>
                        {renderInput("text", "motherName", "Mother's Name", formData?.motherName)}
                        <div className="form-floating mb-5">
                          <PhoneInput
                            readOnly
                            defaultCountry="IN"
                            displayInitialValueAsLocalNumber={true}
                            underlineColorAndroid="transparent"
                            placeholder="Enter phone number"
                            className="form-control cursor-pointer rounded rounded-0 border border-dark"
                            value={formData?.motherNumber}
                            onChange={setPhone}
                            onClick={() => togglePopup("motherNumber")}
                          />
                          <label htmlFor="floatingInput">Mother's Contact Number</label>
                          {renderPopup("motherNumber")}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Residential Details */}
                  <div id="third" className="residential-details scrollspy-section" data-scrollspy-id="third">
                    <Row>
                      <h4 className="fs-24 fw-semibold mb-5">Residential Details</h4>
                      <Col lg={6} md={12}>
                        <div className="form-floating mb-5">
                          <textarea
                            readOnly
                            className="form-control cursor-pointer rounded rounded-0 border border-dark"
                            style={{ height: "80px" }}
                            placeholder="Leave a comment here"
                            id="PermanentAddress"
                            value={formData?.permanentAddress}
                            onClick={() => togglePopup("permanentAddress")}
                          ></textarea>
                          <label htmlFor="PermanentAddress">Permanent Address</label>
                          {renderPopup("permanentAddress")}
                        </div>
                        {renderInput("text", "state", "State", formData?.state)}
                        {renderInput("text", "pincode", "Pincode", formData?.pincode)}
                      </Col>
                      <Col lg={6} md={12}>
                        <div className="form-floating mb-5">
                          <textarea
                            readOnly
                            className="form-control cursor-pointer rounded rounded-0 border border-dark"
                            style={{ height: "80px" }}
                            placeholder="Leave a comment here"
                            id="CurrentAddress"
                            value={formData?.currentAddress}
                            onClick={() => togglePopup("currentAddress")}
                          ></textarea>
                          <label htmlFor="CurrentAddress">Current Address</label>
                          {renderPopup("currentAddress")}
                        </div>
                        {renderInput("text", "city", "City", formData?.city)}
                      </Col>
                    </Row>
                  </div>

                  {/* Course Details */}
                  <div id="fourth" className="course-details scrollspy-section" data-scrollspy-id="fourth">
                    <Row>
                      <h4 className="fs-24 fw-semibold mb-5">Course Details</h4>
                      <Col lg={6} md={12}>
                        {renderInput("text", "courseName", "Course", formData?.courseName)}
                        {renderInput("text", "degree", "Degree", formData?.degree)}
                      </Col>
                      <Col lg={6} md={12}>
                        {renderInput("text", "employmentStatus", "Employment Status", formData?.employmentStatus)}
                        {renderInput("text", "source", "Source", formData?.source)}
                      </Col>
                    </Row>
                  </div>

                  <div className="form-check d-flex gap-3 mb-3 align-items-center">
                    <input
                      className="form-check-input border-dark border-2 fs-5"
                      type="checkbox"
                      value=""
                      id="Studentdetails_checkbox"
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                    <label className="form-check-label fs-18 pt-1" htmlFor="Studentdetails_checkbox">
                      Given above details are Verified by me
                    </label>
                  </div>
                  <div className="d-flex justify-content-start">
                    <button disabled={!checked} className="btn btn-primary px-5 rounded rounded-1" onClick={() => submitData()}>
                      Confirm
                    </button>
                  </div>
                </ScrollSpy>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

