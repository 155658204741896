import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FiPlus } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import UploadModal from "../Modals/UploadModal.js";
import StudentProfileCard from "../Component/StudentProfileCard.js"
import EditProfileModal from '../Modals/EditProfileModal.js';
import React, { useState, useEffect } from "react";
import { allImages } from "../Images.js";

function UploadTab() {
    const [key, setKey] = useState("Works");
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992); // 992px is the breakpoint for lg
    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 992);
      };
      window.addEventListener("resize", handleResize);
  
     // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
  
    }, []);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);
    const handleEditModalShow = () => setShowEditModal(true);
  
    return (
      <>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 border  border-0 pt-4 ps-1 "
        >
          <Tab
            eventKey="Works"
            className="border border-0 upload-tab-works ps-4"
            title="Works"
          >
            <aside className="Works-upload">
              <div className="upload-border">
                <Button
                  className="upload-button btn btn-dark rounded rounded-0 text-white"
                  type="button"
                  onClick={() => setShowUploadModal(true)}
                >
                  <FiPlus size={"32px"} />
                </Button>
              </div>
            </aside>
          </Tab>
         
          {isSmallScreen && (
            <Tab
              eventKey="Profile"
              className="border border-0 ps-4 profile-page "
              title="Profile"
            >
                 <StudentProfileCard setEditModal={handleEditModalShow} />
                <Button  onClick={() => setShowUploadModal(true)} variant="primary" className="rounded rounded-1 m-3 mx-auto d-block">
                  Upload Works
                </Button>
                <EditProfileModal showModal={showEditModal} handleClose={handleEditModalClose} allImages={allImages} />
            
            </Tab>
          )}
        </Tabs>
  <UploadModal    showUploadModal={showUploadModal} 
          setShowUploadModal={setShowUploadModal} />
      
      </>
    );
  }
  

  export default UploadTab;