import React from "react";
// import { Col } from "react-bootstrap";
import { MdHomeFilled } from "react-icons/md";
import { FaUserLarge } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import StickyBox from "react-sticky-box";
import {useNavigate} from 'react-router-dom'
const SideNavBar = () => {
  const navigate = useNavigate()

  const onLogout = ()=>{
    localStorage.clear();
    navigate("/")
  }
  return (
    <>
      <StickyBox
        // offsetTop={93}
        offsetTop={93}
        md={1}
       
        className="side-navbar  bg-black text-light py-2 d-lg-flex d-none flex-lg-column align-items-center justify-content-lg-start justify-content-evenly gap-3"
      >
        {/* <Col
        md={1}
        className="side-navbar  bg-black text-light py-2 d-lg-flex d-none flex-lg-column align-items-center justify-content-lg-start justify-content-evenly gap-3 mt-2"
       > */}
        {/* Home */}
        <button className="btn btn-home text-light d-flex flex-column align-items-center justify-content-center gap-2">
          <MdHomeFilled size={24} />
          <label className="label-home fs-12">Home</label>
        </button>

        {/* Profile */}
        <button className="btn btn-home text-light d-flex flex-column align-items-center justify-content-center gap-2">
          <FaUserLarge size={24} />
          <label className="label-home fs-12">Profile</label>
        </button>

        {/* Notification */}
        <button className="btn btn-home text-light d-flex flex-column align-items-center justify-content-center  gap-2">
          <FaBell size={24} />
          <label className="label-home fs-12">Notification</label>
        </button>

        {/* Logout */}
        <button onClick={()=>onLogout()} className="btn btn-home text-light d-flex flex-column align-items-center justify-content-center  gap-2 mt-auto">
          <LuLogOut size={24} />
          <label className="label-home fs-12">Logout</label>
        </button>

        {/* <SideNavBar /> */}
        {/* </Col> */}
      </StickyBox>
    </>
  );
};

export default SideNavBar;
