import React from "react";
import { Card, Button } from "react-bootstrap";
import { FaCakeCandles } from "react-icons/fa6";
import { FaGraduationCap } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { PiLinkSimpleBold } from "react-icons/pi";
import { FaShare } from "react-icons/fa";
import { TiEdit } from "react-icons/ti";
import { allImages } from "../Images.js";
import { Badge } from 'primereact/badge';
const StudentProfileCard = ({ setEditModal }) => {
  return (
    <Card className="rounded rounded-0 p-2 m-0 bg-light">
      <figure className="position-relative">
        <Card.Img variant="top" src={allImages.studentHomeProfileCard} />
        <Button
          onClick={setEditModal}
          className="btn-primary position-absolute top-0 m-2 right-0 px-2 p-0 d-block 
            d-flex flex-column justify-content-center align-items-center"
        >
          <TiEdit size={32} />
          <span className="fs-12">Edit</span>
        </Button>
        <div className="updated-time fw-semibold fs-12 position-absolute bottom-0 right-0 m-2
             bg-white text-secondary p-2 rounded rounded-0">
          Updated On: <span> {new Date().toUTCString()}</span>
        </div>
      </figure>
      <Card.Body>
        <Card.Title className="d-flex justify-content-start align-items-start gap-3">
          <div className="badge bg-prime p-2 fs-16 rounded rounded-1 mt-1 fw-medium">
            Student
          </div>
          <div className="student__name__course">
            <h4 className="text-black fs-20 m-0">RAJASEKAR</h4>
            <p className="text-secondary fs-16">Ux UI Design</p>
          </div>
        </Card.Title>
        <div className="card-text">
          <div className="d-flex justify-content-between m-0 align-items-start">
            <div className="birth text-dark fw-semibold fs-18">
              <div className="d-flex align-items-center gap-3 m-0 mb-2">
                <FaCakeCandles size={24} /> 25
              </div>
              <div className="d-flex align-items-center gap-3 m-0 mb-2">
                <FaGraduationCap size={24} /> BE mechanical
              </div>
            </div>
            <div className="years__badge d-flex justify-content-center gap-2 border border-1 border-dark p-0 align-items-center p-2 rounded rounded-3">
              <div className="badge-number">
                <Badge value="2" size="medium" className="bg-success" />
              </div>
              <div className="badge-text fw-bold">years</div>
            </div>
           {/*  For Freshers */}
            {/* <div className="years__badge d-flex justify-content-center
             badge-fresher gap-2  p-0 align-items-center p-2 
             rounded rounded-3" >
              
              <div className="badge-text fw-semibold text-success">Fresher</div>
            </div> */}
          </div>
          <div className="area-interest">
            <div className="d-flex text-dark fw-semibold m-0 fs-18 align-items-center gap-3">
              <FaUserTie size={24} />
              Area of interest
            </div>
            <div className="text-secondary m-0 ps-5">
              UI/UX designer, visual designer
            </div>
          </div>
          <div className="student__profile__location my-3 m-0">
            <div className="current-locations 
      d-flex flex-column justify-content-start border-end border-secondary align-items-start ">
              <div className="m-0 text-black fw-semibold">Current location</div>
              <span>
                <FaLocationDot color="#BB0000" /> Madurai
              </span>
            </div>
            <div className="preferred-location px-3 text-lg-start text-cente">
              <div className="m-0 text-black fw-semibold">
                Preferred Location
              </div>
              <span >Madurai,Coimbatore, Bangalore</span>
            </div>
          </div>
          <div className="student__profile__connections d-flex justify-content-around">
            <div className="cursor-disable resume-std d-flex flex-column justify-content-center align-items-center">
              <FaUser color="gray" size={28} />
              <div className="fs-14 text-secondary">Resume</div>
            </div>
            <div // onClick={connecthandleShow}
            className="cursor-disable connect-std d-flex flex-column justify-content-center align-items-center">
              <PiLinkSimpleBold color="gray" size={28} />
              <div className="fs-14 text-secondary">Connect</div>
            </div>
            <div // onClick={() => setSdhareCanvas(true)}
            className="cursor-disable  share-std d-flex flex-column justify-content-center align-items-center">
              <FaShare color="gray" size={28} />
              <div className="fs-14 text-secondary">Share</div>
            </div>
          </div>
        </div>
        {/* Student profile content */}
      </Card.Body>
    </Card>
  );
};

export default StudentProfileCard;
