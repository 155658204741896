import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { allImages } from "../Images";
import { MultiSelect } from "primereact/multiselect"; // Assuming you're using PrimeReact MultiSelect

export const Preference = () => {
  const [preferredrole, setPreferredRole] = useState(null);
  const [preferredlocation, setPreferredLocation] = useState(null);
  const [preferredsalary, setPreferredSalary] = useState(null);
  const [employmenttype, setEmploymentType] = useState(null);
  const [experience, setExperience] = useState(null);
  const navigate = useNavigate();
  const location = useLocation()
  const courses = [
    { name: "UI/UX Designer", code: "ui/ux" },
    { name: "2D & 3D animator", code: "2d3d" },
    { name: "Web Designer", code: "web" },
    { name: "Graphic Designer", code: "gd" },
    { name: "VFX animator", code: "gd" }
  ];
  const cities = [
    { name: "Madurai", code: "ui/ux" },
    { name: "Chennai", code: "2d3d" },
    { name: "Bangalore", code: "web" },
    { name: "Salem", code: "gd" },
    { name: "Trichy", code: "gd" }
  ];

  const salary = [
    { name: "0 to 1l", code: "ui/ux" },
    { name: "1 to 1.5", code: "2d3d" },
    { name: "1.5 to 2.5", code: "web" },
    { name: "2.5 to 3.5", code: "gd" }
  ];
  const workTime = [
    { name: "Full Time Work", code: "ui/ux" },
    { name: "Part Time Work", code: "2d3d" },
    { name: "Freelancer Work", code: "web" },
    { name: "Nightshift Work", code: "gd" }
  ];
  const expYears = [
    { name: "Fresher", code: "ui/ux" },
    { name: "0 to 1 yr", code: "2d3d" },
    { name: "1 to 2 yr", code: "web" },
    { name: "2 to 3 yr", code: "gd" },
    { name: "5 above", code: "3s" }
  ];
  const validationSchema = Yup.object({
    preferredrole: Yup.array()
      .min(1, "Select at least one preferred role")
      .required("Required"),
    preferredlocation: Yup.array()
      .min(1, "Select at least one preferred location")
      .required("Required"),
    preferredsalary: Yup.array()
      .min(1, "Select at least one preferred salary")
      .required("Required"),
    employmenttype: Yup.array()
      .min(1, "Select at least one employment type")
      .required("Required"),
    experience: Yup.array()
      .min(1, "Select any one experience")
      .max(1, "Select any one experience")
      .required("Required")
  });

  const onSubmit =async(values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(false);
    const preferredRole = preferredrole.map(val => val.name).join(",")
    const preferredLocation = preferredlocation.map(val =>  val.name).join(",")
    const preferredSalary = preferredsalary.map(val => val.name).join(",")
    const employmentType = employmenttype.map(val => val.name).join(",")
    const Experience = experience.map(val => val.name).join(",")

    console.log("onsublittttt",preferredRole,preferredLocation,preferredSalary,employmentType,Experience);
    navigate("/suggestFriends",{state:{...location.state,...{preferredRole,preferredLocation,preferredSalary,employmentType,Experience}}}); 
  }
  return (
    <Container fluid className="p-0 signIn__commonBg cmn__bgAligner">
      <Row className="h-100 align-items-center">
        <Col
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <img src={allImages.logo} className="img-fluid signIn__logo" alt="" />
        </Col>
        <Col md={6} className="d-flex justify-content-center">
          <div className="custom-pad text-center preference__formHolder">
            <h3 className="fw-semibold mb-5 pb-5">Student Preference</h3>
            <Formik
              initialValues={{
                preferredrole: [],
                preferredlocation: [],
                preferredsalary: [],
                employmenttype: [],
                experience: []
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, handleSubmit }) =>
                <Form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <MultiSelect
                      value={preferredrole}
                      onChange={e => {
                        setPreferredRole(e.value);
                        setFieldValue("preferredrole", e.value);
                      }}
                      options={courses}
                      optionLabel="name"
                      placeholder="Preferred Role"
                      maxSelectedLabels={4}
                      className="w-full multisel  border border-dark rounded rounded-0 custom-multiselect"
                    />
                    <ErrorMessage
                      name="preferredrole"
                      component="div"
                      className="error-fields"
                    />
                  </div>

                  <div className="mb-4">
                    <MultiSelect
                      value={preferredlocation}
                      onChange={e => {
                        setPreferredLocation(e.value);
                        setFieldValue("preferredlocation", e.value);
                      }}
                      
                      options={cities}
                      optionLabel="name"
                      placeholder="Preferred Location"
                      maxSelectedLabels={4}
                      className="w-full multisel  border border-dark rounded rounded-0 custom-multiselect"
                    />
                    <ErrorMessage
                      name="preferredlocation"
                      component="div"
                      className="error-fields"
                    />
                  </div>

                  <div className="mb-4">
                    <MultiSelect
                      value={preferredsalary}
                      onChange={e => {
                        setPreferredSalary(e.value);
                        setFieldValue("preferredsalary", e.value);
                      }}
                      options={salary}
                      optionLabel="name"
                      placeholder="Preferred Salary"
                      maxSelectedLabels={4}
                      className="w-full multisel  border border-dark rounded rounded-0 custom-multiselect"
                    />
                    <ErrorMessage
                      name="preferredsalary"
                      component="div"
                      className="error-fields"
                    />
                  </div>

                  <div className="mb-4">
                    <MultiSelect
                      value={employmenttype}
                      onChange={e => {
                        setEmploymentType(e.value);
                        setFieldValue("employmenttype", e.value);
                      }}
                      options={workTime}
                      optionLabel="name"
                      placeholder="Employment Type"
                      maxSelectedLabels={4}
                      className="w-full multisel  border border-dark rounded rounded-0 custom-multiselect"
                    />
                    <ErrorMessage
                      name="employmenttype"
                      component="div"
                      className="error-fields"
                    />
                  </div>

                  <div className="mb-4">
                    <MultiSelect
                      value={experience}
                      onChange={e => {
                        setExperience(e.value);
                        setFieldValue("experience", e.value);
                      }}
                      options={expYears}
                      optionLabel="name"
                      placeholder="Experience"
                      maxSelectedLabels={4}
                      className="w-full multisel border border-dark rounded rounded-0 custom-multiselect"
                    />
                    <ErrorMessage
                      name="experience"
                      component="div"
                      className="error-fields"
                    />
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className=" primary__btn rounded-1 mt-3"
                    >
                      Next
                    </button>
                  </div>
                </Form>}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
