import config from "../config/config";
const CryptoJS = require("crypto-js");
export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0) ||
  (typeof value === "string" && value === "0") ||
  (typeof value === "number" && value === 0) ||
  (typeof value === "boolean" && value === false);

export function encryptData(data) {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), config.Secret).toString();
  } catch (e) {
    console.log("error on encryptData", e);
  }
}

export function decryptData(cipherText) {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, config.Secret);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    console.log("error on decryptData", e);
  }
}

export function obfuscateEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    return email;
  }
  const [localPart, domainPart] = email.split("@");

  let obfuscatedLocalPart;
  if (localPart.length > 3) {
    obfuscatedLocalPart = localPart.substring(0, 3) + "***";
  } else {
    obfuscatedLocalPart = localPart + "***";
  }

  return `${obfuscatedLocalPart}@${domainPart}`;
}

export const setLocalStorage = (id, data) => localStorage.setItem(id, data);
export const getLocalStorage = (id) => localStorage.getItem(id);

export const changeOn = async (id, value, callBack, callBack2) => {
  var e = {
    target: {
      id,
      value,
    },
  };
  callBack(e);
  if (callBack2) callBack2({});
};
