import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./test.css";

export const Sample2 = () => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".scrollspy-section");
      const scrollPosition = window.scrollY;

      sections.forEach(section => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop - sectionHeight * 0.5 &&
          scrollPosition < sectionTop + sectionHeight - sectionHeight * 0.5
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="scrollspy-container">
      <nav className="scrollspy-nav">
        <ul>
          <li
            className={activeSection === "section1" ? "active" : ""}
            onClick={() => scrollToSection("section1")}
          >
            <a href="#section1">Section 1</a>
          </li>
          <li
            className={activeSection === "section2" ? "active" : ""}
            onClick={() => scrollToSection("section2")}
          >
            <a href="#section2">Section 2</a>
          </li>
          <li
            className={activeSection === "section3" ? "active" : ""}
            onClick={() => scrollToSection("section3")}
          >
            <a href="#section3">Section 3</a>
          </li>
        </ul>
      </nav>
      <div className="scrollspy-content">
        <div id="section1" className="scrollspy-section">
          Section 1 Content
        </div>
        <div id="section2" className="scrollspy-section">
          Section 2 Content
        </div>
        <div id="section3" className="scrollspy-section">
          Section 3 Content
        </div>
      </div>
    </div>
  );
};
