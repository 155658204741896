import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { allImages } from "../Images";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import { changePassword } from "../axios/student.axios";
import { toast } from "react-toastify";
const ResetPassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
console.log("statestate",state);
  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values);

    setSubmitting(false);

    const resp = await changePassword({
      email: state?.email,
      password: values?.newPassword,
    });

    toast[resp?.success ?? "error"](resp?.msg ?? "Error");
    console.log("jajajjjaja", resp);
    if (resp?.status) {
      setTimeout(() => {
          navigate("/");
      }, 1500);
    }
    setSubmitting(true);

    // setIsSubmitted(true);
    // setSubmitting(false);
  };

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Container fluid className="p-0 signIn__commonBg cmn__bgAligner">
        <Row className="h-100 align-items-center">
          <Col
            xl={6}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={allImages.logo}
              className="img-fluid signIn__logo"
              alt="Logo"
            />
          </Col>
          <Col xl={6} className="d-flex justify-content-center">
            <div className="custom-pad text-center preference__formHolder">
              <h3 className="fw-semibold mb-5 pb-5">Reset Password</h3>
              {isSubmitted ? (
                <div className="d-flex justify-content-center align-items-center flex-column gap-3">
                  <img
                    src={allImages.passwordSuccesss}
                    className="img-fluid d-block"
                    alt="Success"
                  />
                  <p className="fs-24 fw-semibold text-dark">
                    Password Created <br></br> Successfully!!
                  </p>
                  <button
                    onClick={() => navigate("/")}
                    className="primary__btn rounded-1 "
                  >
                    Done
                  </button>
                </div>
              ) : (
                <Formik
                  initialValues={{
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={Yup.object({
                    newPassword: Yup.string()
                      .min(8, "Password must be at least 8 characters")
                      .required("Enter the new password"),
                    confirmPassword: Yup.string()
                      .oneOf(
                        [Yup.ref("newPassword"), null],
                        "Passwords must match"
                      )
                      .required("Confirm your new password"),
                  })}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="sign-inpass mb-4">
                      <div className="form-floating">
                        <Field
                          type={showNewPassword ? "text" : "password"}
                          className="form-control rounded rounded-0 border border-dark"
                          id="newPassword"
                          name="newPassword"
                          placeholder="New Password"
                        />
                        <FaEye
                          className="signIn__eye pointer"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          size={23}
                        />
                        <label htmlFor="newPassword">New Password</label>
                      </div>
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="error-fields"
                      />
                    </div>
                    <div className="sign-inpass mb-4">
                      <div className="form-floating">
                        <Field
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control rounded rounded-0 border border-dark"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                        />
                        <FaEye
                          className="signIn__eye pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          size={23}
                        />
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error-fields"
                      />
                    </div>
                    <div className="sign__formInps d-flex flex-column justify-content-center align-items-center gap-4">
                      <button
                        type="submit"
                        className="primary__btn rounded-1 mt-5"
                      >
                        Create Password
                      </button>

                      <NavLink to="/OtpVerify">
                        <p className="m-0 fs-16">Cancel</p>
                      </NavLink>
                    </div>
                  </Form>
                </Formik>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
